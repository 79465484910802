














































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { OfficeRec2Count, DEFAULT_REC2_COUNT } from "@/model/agreement";

@Component({})
export default class Rec2Viewer extends Mixins(AxiosMixin, UtilMixin) {
  /** 記録書II請求情報 */
  @Prop({ default: () => DEFAULT_REC2_COUNT })
  readonly rec2Count!: OfficeRec2Count;

  @Prop({ default: true }) readonly isDisplayTotal!: boolean;

  @Prop({ default: "記録書II 請求情報" }) readonly title!: string;
}
