



















































































































































































































































































































































































































































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import { Office } from "@/model/office";
import { Billing } from "@/model/agreement/billing";
import { OfficeRec2Count, DEFAULT_REC2_COUNT } from "@/model/agreement";
import {
  PREFECTURES,
  USE_RECEIPT_TYPE,
  STOP_REASON,
  AGREEMENT_PLAN_TYPE,
  NEED_RECEIPT_SYSTEM_MAIL,
  BANK_SELECTION,
  BANK_TYPE,
  BRANCH_TYPE,
  DEPOSIT_TYPE,
  IBOW_RECEIPT_DIV,
  RECEIPT_IS_SEND_RECEIPT_SYSTEM,
  IS_OCR_ENABLED,
  USE_OJT,
} from "@/consts/const";
import BillingFileViewer from "@/components/contractreception/officeinfo/BillingFileViewer.vue";
import Rec2Viewer from "@/components/contractmanagement/Rec2Viewer.vue";
import KintaiLinkInfoViewer from "@/components/contract/KintaiLinkInfoViewer.vue";
import {
  KintaiLinkInfo,
  DEFAULT_KINTAI_LINK_INFO,
} from "@/model/agreement/kintaiLink";
import ChikenLinkInfoViewer from "@/components/contract/ChikenLinkInfoViewer.vue";
import {
  ChikenLinkInfo,
  DEFAULT_CHIKEN_LINK_INFO,
} from "@/model/agreement/chikenLink";
import ReceiptPreCheckInfoViewer from "@/components/contract/ReceiptPreCheckInfoViewer.vue";
import AiReportInfoViewer from "@/components/contract/AiReportInfoViewer.vue";

@Component({
  components: {
    TextDayChangePicker,
    BillingFileViewer,
    Rec2Viewer,
    KintaiLinkInfoViewer,
    ChikenLinkInfoViewer,
    ReceiptPreCheckInfoViewer,
    AiReportInfoViewer,
  },
})
export default class OfficeInfo extends Mixins(UtilMixin) {
  @Prop({ default: {} as Office }) office!: Office;
  @Prop({ default: {} as Billing }) billing!: Billing;
  /** 記録書IIデータを表示するかどうかのフラグ */
  @Prop({ default: false }) needRec2!: boolean;

  /** 記録書IIデータを表示するかどうかのフラグ(利用者別) */
  @Prop({ default: false }) need_rec2_patient!: boolean;

  /** iBowKintai連携情報 */
  @Prop({ default: () => DEFAULT_KINTAI_LINK_INFO })
  kintaiLinkInfo!: KintaiLinkInfo;

  @Prop({ default: 0 })
  isAttendanceUse!: number;

  /** iBow治験連携情報 */
  @Prop({ default: () => DEFAULT_CHIKEN_LINK_INFO })
  chikenLinkInfo!: ChikenLinkInfo;

  @Prop({ default: 0 })
  isChikenUse!: number;

  /** 記録書II集計情報 */
  @Prop({ default: () => DEFAULT_REC2_COUNT }) rec2Count!: OfficeRec2Count;

  /** 記録書II集計情報(利用者別) */
  @Prop({ default: () => DEFAULT_REC2_COUNT })
  rec2PatientCount!: OfficeRec2Count;

  private PREFECTURES = PREFECTURES;
  private USE_RECEIPT_TYPE = USE_RECEIPT_TYPE;
  private STOP_REASON = STOP_REASON;
  private AGREEMENT_PLAN_TYPE = AGREEMENT_PLAN_TYPE;
  private NEED_RECEIPT_SYSTEM_MAIL = NEED_RECEIPT_SYSTEM_MAIL;
  private BANK_SELECTION = BANK_SELECTION;
  private BANK_TYPE = BANK_TYPE;
  private BRANCH_TYPE = BRANCH_TYPE;
  private DEPOSIT_TYPE = DEPOSIT_TYPE;
  private IBOW_RECEIPT_DIV = IBOW_RECEIPT_DIV;
  private RECEIPT_IS_SEND_RECEIPT_SYSTEM = RECEIPT_IS_SEND_RECEIPT_SYSTEM;
  private IS_OCR_ENABLED = IS_OCR_ENABLED;
  private USE_OJT = USE_OJT;

  /** 統一日付フォーマット */
  private DATE_FORMAT = "yyyy年MM月dd日";

  /** 事業所電話番号(フル) */
  private get telNo() {
    const parts: string[] = [];
    parts.push(this.office.office_info.tel_area);
    parts.push(this.office.office_info.tel_city);
    parts.push(this.office.office_info.tel_num);

    return parts.join(" - ");
  }

  /** 試用期間(文字列) */
  private get trialPeriodStr(): string {
    // 開始・終了がともに空の場合は空文字列を返却
    if (
      this.billing.trial_start_date == "" &&
      this.billing.trial_end_date == ""
    ) {
      return "";
    }

    const startDt = this.newDate(this.billing.trial_start_date);
    const endDt = this.newDate(this.billing.trial_end_date);

    return `
    ${this.dateToStr(startDt, this.DATE_FORMAT)} 〜 
    ${this.dateToStr(endDt, this.DATE_FORMAT)}`;
  }
}
